import API from "@/httpsAPI/API"
import request from "@/utils/request"
import {downloadFile} from "@/httpsAPI/common"

// 交付物列表查询
export function getProjectManageDeliverablesList(params) {
  return request({
    url:API.projectDeliverablesList,
    method:'post',
    data:params
  })
}

// 删除目标交付物
export function deleteDeliverables(params) {
    return request({
      url: '/project/deliverables/file-delete/' + params,
      method:'post',
      data:params
    })
  }

// 交付物详情
export function detailDeliverables(params) {
  return request({
    url: 'project/deliverables/detail/' + params.id,
    method:'post',
    data:params
  })
}

// 批量下载接口
export function batchDownload(fileName, params) {
  return downloadFile('/project/deliverables/batch-download', fileName, params, 'post')
}

// 交付物单一下载
export function fileDownload(params) {
  return downloadFile('/project/deliverables/file-download/' + params.id, params.fileName, null, 'post')
}

// 移除交付物文件
export function fileDelete(params) {
  return request({
    url: '/project/deliverables/file-delete/' + params.id,
    method:'post',
    data:{}
  })
}